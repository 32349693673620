
import sBackground from "@/assets/img/error/hero.jpg";
import { Vue, Component } from "vue-property-decorator";
import Hero from "@/components/common/Hero.vue";

@Component({
  components: { Hero },
})
export default class PageError extends Vue {
  bgImg: string | null = null;

  mounted(): void {
    this.$nextTick(() => {
      this.bgImg = sBackground;
    });
  }
}
